#List {
  padding: 15px 25px;
  .list-header {
    display: flex;
    align-items: center;
  }
  .btn-back {
    color: #8a8bb9;
    background-color: #fff;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  h3 {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    margin: 0 0 0 -40px;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
  }
  .list-main {
    margin: 15px 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .item {
      padding: 6px;
      display: inline-flex;
      width: 33.333333%;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      margin-bottom: 10px;
      .img {
        display: block;
        background-color: #fff;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: 5px auto 10px auto;
      }
      h4 {
        margin: 0;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 98.9%;
        text-align: center;
      }
    }
    .no-result {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
    }
  }
  .divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #fff;
    opacity: 0.3;
    margin: 15px 0;
  }
  #DonateBanner {
    padding: 0;
    background: none;
    margin: 30px 0;
  }
}
