@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

#Featured {
  width: 100%;
  h3 {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    padding-left: 40px;
    margin-bottom: 0;
  }
  .featured-item {
    width: 295px;
    height: 159px;
    background: linear-gradient(
      211.15deg,
      rgba(190, 216, 224, 0.7) 12.52%,
      rgba(140, 155, 205, 0.7) 86.73%
    );

    border-radius: 30px;
    transition: all 0.4s;
    padding: 20px;
    &-wrapper {
      width: 325px;
      height: 189px;
      padding: 15px 15px 15px 15px;
    }
    span {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      display: flex;
      margin-bottom: 15px;
      img {
        width: 22px;
        margin-right: 8px;
      }
    }
    h4 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      margin: 0 0 10px 0;
    }
    p {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      opacity: 0.7;
      margin: 0;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-list {
    // overflow: visible;
    padding: 0 15px !important;
  }
  .slick-track {
    height: 189px;
    overflow: hidden;
  }
  .slick-slide {
    // width: 325px !important;
    &.slick-active {
      .featured-item {
        background: linear-gradient(
          211.15deg,
          rgba(208, 172, 225, 0.7) 12.52%,
          rgba(140, 155, 205, 0.7) 86.73%
        );
        box-shadow: 0 0 15px 0 rgba(54, 39, 98, 0.8);
      }
    }
  }
}
