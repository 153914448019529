#DonateBanner {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(34px);
  padding: 13px 30px;
  display: flex;
  justify-content: space-between;
  p {
    display: inline-flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    color: #fff;
    margin: 0;
  }
  a {
    height: 40px;
    padding: 0 15px;
    color: #5e66a5;
    font-size: 10px;
    font-weight: 700;
    background-color: #fff;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    img {
      width: 28px;
      margin-right: 8px;
      display: inline-block;
    }
  }
}
