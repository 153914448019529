#Onboarding {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(/assets/images/bg-onboarding.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  &.connecting {
    .logo,
    .slide,
    .btn-bottom,
    .dots {
      opacity: 0;
    }
    .slide-connecting {
      opacity: 1;
    }
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    transition: opacity 0.3s;
    text-align: center;
    img {
      width: 165px;
      height: 46px;
    }
  }
  .slide {
    position: absolute;
    top: 120px;
    width: 100%;
    padding: 0 45px;
    color: #5e66a5;
    transition: all 0.3s;
    &.onReady {
      transform: translateX(101%);
    }
    &.onShow {
      transform: translateX(0);
    }
    &.onHide {
      transform: translateX(-101%);
    }
  }
  .slide-1 {
    h1 {
      font-size: 38px;
      line-height: 1.25;
      small {
        font-size: 22px;
      }
    }
  }
  .slide-2 {
    p {
      font-weight: 700;
      font-size: 21px;
      margin-bottom: 80px;
    }
    b {
      font-weight: 800;
    }
  }
  .btn-bottom {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 120px;
    height: 40px;
    color: #fff;
    transition: opacity 0.3s;
  }
  .dots {
    position: absolute;
    bottom: 47px;
    left: 30px;
    transition: opacity 0.3s;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      opacity: 0.2;
      transition: opacity 0.25s;
      background-color: #fff;
    }
    &:before {
      opacity: 1;
    }
    &:after {
      left: 15px;
    }
    &.slide-2 {
      &:before {
        opacity: 0.2;
      }
      &:after {
        opacity: 1;
      }
    }
  }
  .slide-connecting {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 0.3s;
    opacity: 0;
    p {
      position: absolute;
      width: 150px;
      left: -30px;
      top: 0;
      margin-top: 130px;
      color: #5e66a5;
      text-align: center;
    }
    .icon-connected {
      position: relative;
      width: 90px;
      height: 90px;
      background-color: #5e66a5;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: #fff;
      svg {
        font-size: 40px;
      }
    }
  }
}
