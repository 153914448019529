#HelperTooltip {
  transition: all 0.3s;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  &.close {
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    .help-main {
      transform: translateY(100%);
    }
  }
  &.open {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    .help-main {
      transform: translateY(0);
    }
  }
  .tooltip {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 70px;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    height: 95px;
    border-radius: 50px;
    padding: 10px 30px;
    text-align: center;
    line-height: 1.1;
    display: flex;
    align-items: center;
    background: linear-gradient(
      211.15deg,
      rgba(208, 172, 225, 0.7) 12.52%,
      rgba(140, 155, 205, 0.7) 86.73%
    );
    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background-color: #fff;
      z-index: -1;
      left: 0;
      top: 0;
    }
    p {
      color: #fff;
      margin: 0;
      font-size: 15px;
    }
    button {
      white-space: nowrap;
      flex-shrink: 0;
      margin-left: 10px;
      margin-right: -15px;
    }
  }
}
