#Disconnect {
  transition: all 0.3s;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  &.close {
    background-color: rgba(44, 44, 87, 0);
    z-index: -1;
    .help-main {
      transform: translateY(100%);
    }
  }
  &.open {
    background-color: rgba(44, 44, 87, 0.8);
    z-index: 5;
    .help-main {
      transform: translateY(0);
    }
  }
  .wrapper {
    width: calc(100vw - 60px);
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
    position: absolute;
    text-align: center;
    img {
      width: 112px;
    }
    p {
      font-weight: 700;
      font-size: 20.9174px;
      line-height: 126.9%;
      text-align: center;
      color: #5e66a5;
      margin-bottom: 50px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }
    .btn-leave,
    .btn-cancel {
      display: inline-flex;
      background-color: #b42e88;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      height: 40px;
      padding: 0 25px;
    }
    .btn-cancel {
      background-color: #5e66a5;
    }
  }
}
