#Pillars {
  padding: 20px 24px;
  .pillar {
    padding: 6px;
    display: inline-flex;
    width: 50%;
    position: relative;
    .wrapper {
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
    }
    &.donor .wrapper {
      background: linear-gradient(
        211.15deg,
        rgba(190, 216, 224, 0.7) 12.52%,
        rgba(140, 155, 205, 0.7) 86.73%
      );
    }
    &.patient .wrapper {
      background: linear-gradient(
        211.15deg,
        rgba(190, 216, 224, 0.7) 12.52%,
        rgba(140, 155, 205, 0.7) 86.73%
      );
    }
    &.fundraiser .wrapper {
      background: linear-gradient(
        211.15deg,
        rgba(208, 172, 225, 0.7) 12.52%,
        rgba(140, 155, 205, 0.7) 86.73%
      );
    }
    &.research .wrapper {
      background: linear-gradient(
        211.15deg,
        rgba(237, 191, 213, 0.7) 12.52%,
        rgba(140, 155, 205, 0.7) 86.73%
      );
    }
    h4,
    p,
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
    }
    img {
      top: 20%;
      width: 24%;
    }
    h4 {
      font-weight: 700;
      font-size: 18px;
      top: 55%;
      margin: 0;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      top: 72%;
      margin: 0;
      white-space: nowrap;
    }
  }
}
