#Main {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url(/assets/images/bg-main.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  .main-body {
    position: absolute;
    left: 0;
    top: 130px;
    width: 100%;
    height: calc(100% - 130px);
    overflow: auto;
    transition: opacity 0.3s;
    &.hide {
      opacity: 0;
    }
    &.show {
      opacity: 1;
    }
  }
  .logo {
    margin: 0 0 30px 0;
    text-align: center;
    img {
      width: 200px;
    }
  }
}
