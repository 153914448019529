#SessionEnded {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(/assets/images/bg-onboarding.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  &.open {
    z-index: 6;
    opacity: 1;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    transition: opacity 0.3s;
    text-align: center;
    img {
      width: 165px;
      height: 46px;
    }
  }
  .text {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100px;
    padding: 0 30px;
    h2 {
      font-weight: 700;
      font-size: 38.792px;
      line-height: 126%;
      color: #5e66a5;
      margin-bottom: 15px;
    }
    p {
      font-weight: 700;
      font-size: 20.9174px;
      line-height: 126.9%;
      color: #5e66a5;
    }
  }
  .buttons {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    padding: 0 30px;
    text-align: center;
    #qr-input-file {
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
      position: absolute;
    }
    .btn-scan-qr {
      background: #5e66a5;

      height: 40px;
      padding: 20px 30px;
      display: inline-flex;
      margin: 0 auto;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      border-radius: 20px;
      align-items: center;
    }
    .btn-error {
      font-weight: 700;
      font-size: 12px;
      line-height: 126.9%;
      text-align: center;
      color: #5e66a5;
      display: block;
      margin: 15px auto;
      text-transform: none;
    }
  }
}
