#Search {
  padding: 0 30px;
  .search-bar {
    background-color: #fff;
    border-radius: 30px;
    width: 100%;
    fieldset {
      border: none;
    }
    input {
      box-sizing: border-box;
      height: 50px;
      padding-left: 25px;
    }
    button {
      margin-right: -6px;
      color: #bdbdbd;
    }
  }
}
