#Browse {
  h3 {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    padding-left: 40px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      font-weight: 700;
      font-size: 16px;
      opacity: 0.5;
      display: inline-block;
      margin-left: 8px;
    }
  }
}
