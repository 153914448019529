#Player {
  position: fixed;
  transition: all 0.4s;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  &.open {
    &.view-category {
      top: 50px;
      height: calc(100% - 50px);
    }
    .mini {
      opacity: 0;
    }
  }
  &.close {
    top: 50px;
    background: linear-gradient(90deg, #6e68a4 22.34%, #c299dd 89.62%);
    box-shadow: 0px 4px 34px rgba(73, 72, 157, 0.8);
    height: 80px;
    .intro,
    .loading,
    .navigation,
    .btn-donate-wrapper,
    .category {
      opacity: 0;
    }
    .mini {
      opacity: 1;
    }
    .intro {
      z-index: -1;
    }
  }
  .mini {
    position: absolute;
    display: flex;
    width: 100%;
    padding: 20px 30px;
    align-items: center;
    top: 0;
    left: 0;
    .icon-remote {
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      img {
        display: block;
      }
    }
    p {
      margin: 0;
      color: #fff;
    }
    .current-category {
      position: relative;
      width: 100%;
      height: 40px;
      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 98.9%;
        color: #fff;
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        padding-right: 30px;
      }
      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 98.9%;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 98.9%;
          opacity: 0.5;
          display: inline-block;
          margin-left: 8px;
        }
      }
    }
    .CircularProgressbar,
    .progress {
      width: 20px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1;
      color: #fff;
      .CircularProgressbar-path {
        stroke: #fff;
      }
      path {
        stroke-width: 10px;
      }
    }
  }
  .btn-browse {
    background: linear-gradient(
      204.73deg,
      #edfff9 7.34%,
      #eef0df 24.24%,
      #ece1ef 49.82%,
      #d4dded 91.38%
    );
    box-shadow: 0 0 7px -2px rgba(255, 255, 255, 0.8);
    height: 40px;
    text-transform: none;
    display: inline-flex;
    font-size: 12px;
    font-weight: 700;
    padding: 0 15px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
  .navigation {
    position: absolute;
    left: 30px;
    bottom: 20px;
    padding: 6px 11px 6px 6px;
    border-radius: 38px;
    background-color: rgba(255, 255, 255, 0.2);
    .btn-previous,
    .btn-next {
      width: 40px;
      height: 40px;
      background: linear-gradient(
        204.73deg,
        #edfff9 7.34%,
        #eef0df 24.24%,
        #ece1ef 49.82%,
        #d4dded 91.38%
      );
      border-radius: 50%;
      padding: 0;
      min-width: 0;
      margin-left: 9px;
    }
  }
  .intro {
    background-color: rgba(44, 44, 87, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: opacity 0.3s;
    .text-highlight {
      position: absolute;
      left: 30px;
      bottom: 70px;
      color: #fff;
      font-size: 21px;
      font-weight: 700;
      height: 95px;
      border-radius: 50px;
      padding: 10px 40px;
      text-align: center;
      line-height: 1.1;
      display: flex;
      align-items: center;
      background: linear-gradient(
        211.15deg,
        rgba(208, 172, 225, 0.7) 12.52%,
        rgba(140, 155, 205, 0.7) 86.73%
      );
    }
    .btn-browse {
      position: absolute;
      left: 36px;
      bottom: 26px;
      box-shadow: 0 0 15px 0px rgba(255, 255, 255, 0.8);
    }
  }
  .btn-donate {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    font-size: 6.5px;
    background: linear-gradient(
      204.73deg,
      #edfff9 7.34%,
      #eef0df 24.24%,
      #ece1ef 49.82%,
      #d4dded 91.38%
    );
    min-width: 0;
    align-items: center;
    line-height: 1.2;
    img {
      width: 20px;
      margin-bottom: 4px;
    }
  }
  .btn-donate-wrapper {
    position: absolute;
    right: 35px;
    bottom: 20px;
  }
  .loading {
    .title {
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 30px;
        background: linear-gradient(
          270.25deg,
          #ffffff 0.93%,
          rgba(255, 255, 255, 0) 103.44%
        );
        opacity: 0.4;
        border-radius: 9px;
        width: 190px;
        height: 33px;
      }
      &:after {
        top: 40px;
        width: 126px;
      }
    }
    .stories {
      z-index: 0;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 30px;
        background: linear-gradient(
          204.73deg,
          #edfff9 7.34%,
          #eef0df 24.24%,
          #ece1ef 49.82%,
          #d4dded 91.38%
        );

        border-radius: 50%;
        width: 192px;
        height: 192px;
        box-shadow: 0 0 50px 0 rgba(255, 255, 255, 0.8);
        z-index: 1;
      }
      &:after {
        top: 14px;
        left: 200px;
        width: 164px;
        height: 164px;
        background: linear-gradient(
          211.15deg,
          rgba(208, 172, 225, 0.7) 12.52%,
          rgba(140, 155, 205, 0.7) 86.73%
        );
        box-shadow: 0 0 17px 0 rgba(109, 109, 109, 0.2);
        z-index: 0;
      }
    }
  }
  .title {
    position: absolute;
    width: 100%;
    padding-left: 30px;
    top: 90px;
  }
  .stories {
    position: absolute;
    width: 100%;
    padding-left: 30px;
    top: 250px;
    height: 192px;
  }

  .category {
    position: relative;
    margin-top: 50px;
    height: 100%;
    h2 {
      font-weight: 700;
      font-size: 43px;
      line-height: 98.9%;
      color: #fff;
      margin: 50px 30px 50px 30px;
    }
    .story-wrapper {
      position: relative;
      &:before,
      &:after {
        content: "";
        background: linear-gradient(
          211.15deg,
          rgba(208, 172, 225, 0.7) 12.52%,
          rgba(140, 155, 205, 0.7) 86.73%
        );
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        left: 0;
        top: 0;
        opacity: 1;
        transition: opacity 0.3s;
        z-index: -1;
      }
      &:after {
        background: linear-gradient(
          204.73deg,
          #edfff9 7.34%,
          #eef0df 24.24%,
          #ece1ef 49.82%,
          #d4dded 91.38%
        );
        opacity: 0;
      }
    }
    .story-item {
      text-align: center;
      border-radius: 50%;
      width: 100%;
      padding-top: 100%;
      transition: all 0.4s;
      box-shadow: 0 0 15px 0 rgba(109, 109, 109, 0.2);
      position: relative;
      z-index: 1;

      h3 {
        position: absolute;
        top: 30%;
        width: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding: 0 30px;
        font-weight: 700;
        font-size: 5.2vw;
        line-height: 108.9%;
        transition: all 0.3s;
        color: #fff;
      }
      p {
        font-weight: 700;
        font-size: 3.8vw;
        color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        bottom: 11.5vw;
        margin: 0;
        opacity: 0.5;
      }
      .icon {
        position: absolute;
        left: 50%;
        bottom: 2vw;
        transform: translateX(-50%);
        width: 9vw;
        color: #fff;
        fill: currentColor;
        stroke: #fff;
        transition: all 0.2s;
        svg {
          width: 100%;
        }
        path {
          fill: currentColor;
          transition: all 0.2s;
        }
      }
    }
    .CircularProgressbar {
      width: calc(50vw + 6px);
      position: absolute;
      left: -3px;
      bottom: -3px;
      z-index: 1;
      path {
        stroke-width: 5px;
      }
    }
  }
  .slick-slider {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 200px;
    .slick-arrow {
      display: none;
      visibility: hidden;
    }
    .slick-list {
      padding: 30px;
    }
    .slick-slide {
      position: relative;
      z-index: 0;
      width: 50vw;
      &.slick-current {
        z-index: 2;
        .story-wrapper {
          &:before {
            opacity: 0;
          }
          &:after {
            opacity: 1;
          }
        }
        .story-item {
          transform: scale(1) translateX(0);
          box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.8);

          h3 {
            color: #5e66a5;
          }
          p {
            color: rgba(94, 102, 165, 0.5);
            opacity: 1;
          }
          .icon {
            color: #5e66a5;
            stroke: #5e66a5;
            path {
            }
          }
        }
        & + .slick-slide {
          z-index: 1;
          transform: scale(0.88) translateX(-15%);
        }
        & + .slick-slide + .slick-slide {
          z-index: 0;
          transform: scale(0.75) translateX(-55%);
        }
      }
    }
  }
  .casting-message {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 180px;
    left: 30px;
  }
}

.CircularProgressbar {
  .CircularProgressbar-path {
    stroke: #5e66a5;
    transition: stroke-dashoffset 0.5s linear 0s;
  }
  .CircularProgressbar-trail {
    display: none;
  }
}
