#Help {
  transition: all 0.3s;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  &.close {
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    .help-main {
      transform: translateY(100%);
    }
  }
  &.open {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    .help-main {
      transform: translateY(0);
    }
  }
  .help-main {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    border-radius: 30px 30px 0 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    height: 80vh;
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 40px;
    button {
      background-color: rgba(94, 102, 165, 0.2);
      color: #5e66a5;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 98.9%;
    color: #5e66a5;
    margin: 0;
  }
  .list {
    .item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 148.4%;
        color: #5e66a5;
      }

      .icon {
        font-size: 1.2em;
        color: #5e66a5;
        transition: all 0.25s;
        transform: rotate(0);
      }
    }
    .content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.25s;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 143.9%;
      color: #5e66a5;
    }
    &-item {
      &.open {
        .content {
          max-height: 150px;
        }
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }
}
