#Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 5px 0;
  .icon-wall {
    width: 53px;
  }
  .btn-disconnect,
  .btn-help {
    button {
      background-color: rgba(255, 255, 255, 0.3);
      color: #ffffff;
      width: 40px;
      height: 40px;
    }
    svg {
      font-size: 0.8em;
    }
  }
  .btn-disconnect {
    padding-left: 30px;
  }
  .btn-help {
    padding-right: 30px;
    svg {
      font-size: 0.7em;
    }
  }
  .text-status {
    h5,
    span {
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
      font-size: 12px;
      line-height: 1.2;
    }
    span {
      color: #fff;
    }
  }
}
